import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// components
import Container from '../../components/container';

import styles from './styles.scss';

const NotFound = () => (
  <Container className={styles.notFound}>
    <Helmet>
      <title>404 | Bon Sano | Funeral Cover</title>
    </Helmet>
    <h2>Page not found!</h2>
    <Link to="/">Go back</Link>
  </Container>
);

export default NotFound;
